import $ from 'jquery';
import 'jquery-validation';
import 'jquery-validation/dist/localization/messages_it';
import 'jquery-validation/dist/localization/methods_it';
import Component from "../models/Component";

export default class FormValidation extends Component {
  constructor(element, context) {
    super(element, context);

    $(() => {
      if (!FormValidation.initialized) {
        FormValidation.customValidators();
        FormValidation.initialized = true;
      }
      this.message = this.element.find('.response-message');

      this.element.find('[data-rule-invoice-date]').on('keyup', function () {
        $('[data-rule-invoice-date]').each(function () { $(this).valid() });
        $('[data-rule-invoice-time]').each(function () { $(this).valid() });
      });

      this.element.find('[data-rule-invoice-time]').on('keyup', function () {
        $('[data-rule-invoice-time]').each(function () { $(this).valid() });
      });

      this.init();
    });
  }

  static customValidators() {
    $.validator.methods.email = function (value, element) {
      return this.optional(element) || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    };

    $.validator.addMethod('validcode', function (value, element, _param) {
      return this.optional(element) || value.match(/^42[12]$/i);
    }, 'Codice non valido');

    $.validator.addMethod('validcode3', function (value, element, _param) {
      return this.optional(element) || value.match(/^\d{3}$/i);
    }, 'Codice non valido');

    $.validator.addMethod('validcode4', function (value, element, _param) {
      return this.optional(element) || value.match(/^\d{4}$/i);
    }, 'Codice non valido');

    $.validator.addMethod('notfuture', function (value, element, param) {
      if (this.optional(element) && value.length === undefined) {
        return true;
      }

      let dateField = $(param);
      if (dateField.length === 0 && dateField.val().length === 0) {
        return false;
      }

      let date = new Date(Date.parse(dateField.val() + 'T' + value + ':00'));
      return date < new Date();
    }, 'Orario non valido');

    $.validator.addMethod('invoice-time', function (_value, element, param) {
      let form = $(element).parents('form');

      // let timeFields = param.split(',');
      // let year = form.find(timeFields[0]);
      // let month = form.find(timeFields[1]);
      // let day = form.find(timeFields[2]);
      // let hour = form.find(timeFields[3]);
      // let minutes = form.find(timeFields[4]);
      //
      // if (
      //   year.val().length === 0 || month.val().length === 0 || day.val().length === 0 ||
      //   hour.val().length === 0 || minutes.val().length === 0
      // ) {
      //   return true;
      // }
      // let date = Date.parse(year.val() + '-' + month.val() + '-' + day.val() + 'T' + hour.val() + ':' + minutes.val() + ':00');

      let timeFields = param.split(',')
      let day = form.find(timeFields[0]);
      let hour = form.find(timeFields[1]);
      let minutes = form.find(timeFields[2]);

      if (day.val().length === 0 || hour.val().length === 0 || minutes.val().length === 0) {
        return true;
      }

      let date = Date.parse(day.val() + 'T' + hour.val() + ':' + minutes.val() + ':00');
      if (date >= Date.parse('2021-11-18T00:00:00') && date <= Date.parse('2022-01-27T00:00:00')) {
        return !isNaN(date) &&
          date >= Date.parse(day.val() + 'T00:00:00') &&
          date <= Date.parse(day.val() + 'T23:59:59') &&
          date <= new Date();
      } else {
        return false;
      }
    }, 'Orario scontrino non valido');

    $.validator.addMethod('amount-units', function (value, element, param) {
      if (this.optional(element) && value.length === undefined) {
        return true;
      }

      return value.match(/^(0|[1-9]\d*)$/i);
    }, 'Importo non valido');

    $.validator.addMethod('amount-decimals', function (value, element, param) {
      if (this.optional(element) && value.length === undefined) {
        return true;
      }

      return value.match(/^\d{2}$/i);
    }, 'Importo non valido');

    $.validator.addMethod('invoice-number', function (value, element, param) {
      if (this.optional(element)) {
        return true;
      }

      return value.match(/^\d{4}-\d{4}$/i);
    }, 'Codice scontrino non valido');
  }

  init() {
    let groups = {};
    this.element.find('[data-group]').each((index, elem) => {
      elem = $(elem);
      if (!groups[elem.data('group')]) groups[elem.data('group')] = elem.attr('name');
      else groups[elem.data('group')] += ' ' + elem.attr('name');
    });
    this.element.validate({
      groups: groups,
      errorElement: 'em',
      focusInvalid: false,
      ignore: '',
      ignoreTitle: true,
      highlight: (element, errorClass, validClass) => {
        $(element).addClass(errorClass).removeClass(validClass);
      },
      unhighlight: (element, errorClass, validClass) => {
        $(element).removeClass(errorClass).addClass(validClass);
      },
      errorPlacement: (error, element) => {
        let parent = $(element).closest('.error-parent');
        if (!parent.length) {
          parent = $(element).parent();
        }
        parent.append(error);
      },
      submitHandler: () => {
        this.element.addClass('loading');
        this.element[0].submit();
      },
    });
  }
}

FormValidation.initialized = false;
