import $ from 'jquery';
import flatpickr from "flatpickr";
import {Italian} from "flatpickr/dist/l10n/it";
import Component from "../models/Component";

export default class Datepicker extends Component {
  constructor(element, context) {
    super(element, context);
    $(() => {
      this.init();
    });
  }

  init() {
    if (this.getData('time')) {
      flatpickr(this.element.get(0), {
        locale: Italian,
        dateFormat: "H:i",
        allowInput: false,
        enableTime: true,
        time_24hr: true,
        noCalendar: true,
        minuteIncrement: 1,
        disableMobile: true,
        onOpen: (selectedDates, dateStr, flatpickrInstance) => {
          // XXX Patch to fix bug when the default value is empty
          if (dateStr === '') {
            flatpickrInstance.setDate('12:00');
          }

          this.element.parent().find('input').removeClass('error');
          this.element.parent().find('em.error').remove();
        },
        onClose: () => {
          console.log('onClose');
          this.element.parent().find('input').removeClass('error');
          this.element.parent().find('em.error').remove();
          this.element.valid();
        }
      });
    } else {
      let maxDate = null;
      if (this.getData('max')) {
        maxDate = Date.parse(this.getData('max'));
      } else if (this.element.prop('max')) {
        maxDate = Date.parse(this.element.prop('max'));
      }

      let minDate = null;
      if (this.getData('min')) {
        minDate = Date.parse(this.getData('min'));
      } else if (this.element.prop('min')) {
        minDate = Date.parse(this.element.prop('min'));
      }

      flatpickr(this.element.get(0), {
        locale: Italian,
        altInput: true,
        altFormat: 'd/m/Y',
        dateFormat: 'Y-m-d',
        allowInput: false,
        maxDate: maxDate,
        minDate: minDate,
        disableMobile: true,
        onOpen: () => {
          this.element.parent().find('input').removeClass('error');
          this.element.parent().find('em.error').remove();
        },
        onClose: () => {
          this.element.valid();
        }
      });
    }
  }

  iPadMobileFix() {
    return function(instance) {
      return {
        onParseConfig: function() {
          if (instance.isMobile) {
            return;
          }
          if (
            window.navigator.userAgent.match(/iPad/i) ||
            window.navigator.userAgent.match(/iPhone/i) ||
            /iPad|iPhone|iPod/.test(window.navigator.platform) ||
            (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)
          ) {
            console.log("Instnace is mobile");
            instance.isMobile = true;
          }

          console.log("Instnace is not mobile");
        }
      };
    };
  };
}
