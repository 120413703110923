import $ from 'jquery'
import { Fancybox } from '@fancyapps/ui'
import { $Body } from './jQueryGlobals'

/**
 * To connect a button to the banner popup do it like this
 *
 * <a href="" data-cookie-policy-button>Cookie Policy</a>
 */
export default class CookiePolicyPopup {
  constructor () {
    $(() => {
      this.init();
    });
  }

  init() {
    $Body().on('click', '[data-cookie-policy-button]', () => {
      this.openCookiePolicyPopup();
      return false;
    })
  }

  openCookiePolicyPopup() {
    new Fancybox(
      [
        {
          src: "#cookie-policy-modal",
          type: 'inline',
        },
      ],
      {
        mainClass: 'cookie-policy-modal',
        autoFocus: false,
        placeFocusBack: false,
        dragToClose: false,
        click: false,
      }
    );
  }
}
