import {$Page} from "../utils/jQueryGlobals";
import Component from '../models/Component'

export default class ScrollTo extends Component {
  constructor (element, context) {
    super(element, context);

    $(() => {
      this.element.on('click', (e) => {
        e.preventDefault()

        const target = $(this.getData('to'))
        $Page().stop().animate({scrollTop: target.offset().top - 20}, 800, 'swing');
      })
    })
  }
}
