import {$Win} from "../utils/jQueryGlobals";
import Component from "../models/Component";

export default class Preloader extends Component {
  constructor(element, context) {
    super(element, context);
    $Win.on('load', () => {
      this.init();
    });
  }

  init() {
    setTimeout(() => {
      this.element.fadeOut(800, () => {
        this.element.remove();
      });
    }, 200);
  }
}