import $ from 'jquery';
import Registry from "./Registry";
import BannerCookie from "../utils/banner-cookie";
import CookiePolicyPopup from "../utils/CookiePolicyPopup";

export default class Context {
  constructor(config) {
    this.config = config;
    this.loadComponents();
    this.defaultContext();
  }

  defaultContext() {
    new CookiePolicyPopup();
  }

  loadComponents(name = false, context) {
    let registry = new Registry(),
      selector = '[data-g-component]';
    if (name) {
      selector = '[data-g-component="' + name + '"]';
    }
    $(selector, context).each((index, elem) => {
      if (!$(elem).data('g-component-initialized')) {
        let Cmp = registry.getComponent($(elem).data('g-component'));
        if (typeof Cmp === 'function') {
          new Cmp(elem, this);
          $(elem).data('g-component-initialized', true);
        }
      }
    });
  }
}
